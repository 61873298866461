.body {
  background-color: rgba(33, 17, 52, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
}
.overlay {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
}
.overlay::-webkit-scrollbar {
  display: none;
}
.radial1 {
  display: flex;
  width: 100%;
  position: absolute;
  top: 7rem;
  width: 150px;
  height: 180px;
  background: rgba(91, 17, 12, 0.955);
  filter: blur(100px);
}
.radial2 {
  display: flex;
  width: 100%;
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(18, 73, 131, 0.673);
  filter: blur(70px);
  bottom: 8rem;
}

.statContent {
  position: absolute;
  /* margin-top: 2rem; */
  /* padding-top: 11rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  /* top: -1rem; */
  overflow-y: scroll;
}
.pfBar {
  width: 93vw;
  margin: 0;
}

.dataBox {
  width: 93vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem 0;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  padding: 5px 0;
}
.columnBox {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.dataColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.head {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  /* text-decoration:underline dashed;
    text-underline-offset: 8px; */
}
.count {
  margin-top: 5px;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  letter-spacing: 2px;
}

.competeTime {
  width: 93vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0.5rem 0;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  padding: 6px 0;
}

.compBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.medalIcon {
  margin-top: 7px;
  width: 18px;
}
.compText {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  margin-left: 5px;
}

.toggleBox {
    margin-top: 0px;
    width: 93vw;
    margin-bottom: 80px;
  }
  
  .toggleButtons {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.305);
  }
  
  .toggleButton {
    flex: 1;
    text-align: center;
    padding: 10px;
    color: white;
    font-size: 18px;
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    background-color: inherit;
    border: none;
    outline: none;
  }

.toggleButtons {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.305);
}

.toggleButton {
  flex: 1;
  text-align: center;
  padding: 10px;
  color: white;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  background-color: inherit;
  border: none;
  outline: none;
}

.toggleButton.active {
  border-bottom: 2px solid rgba(216, 117, 0, 1);
}

.statsList {
  margin-top: 20px;
  overflow-y: scroll;
  max-height: 55vh;
}
.yourRankBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  width: 90vw;
  border-radius: 3rem;
  border: 1px solid rgba(238, 144, 30, 1);
  margin-bottom: 14px;
}
.yourRank {
  color: rgba(247, 247, 247, 1);
  font-size: 12px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  margin-bottom: 5px;
  margin-right: 10px;
}
.yourRank span {
  color: rgba(255, 236, 127, 1);
  font-size: 12px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}
.yourCreditsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}
.yourCreditsIcon {
  width: 28px;
  margin-right: 5px;
}
.yourCreditCount {
  color: rgba(247, 247, 247, 1);
  font-size: 12px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  padding-bottom: 4px;
}

.statsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  border-radius: 3rem;
  margin-bottom: 10px;
}

.userIcon {
  width: 30px;
  height: 30px;
}
.profilePic {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.ppl {
  display: flex;
  top: 0;
}

.ppLevel {
  width: 70px;
  height: 48px;
}
.profilepic {
  position: absolute;
  top: 16.7px;
  left: 27px;
  width: 46px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
}
.frogImg {
  width: 46px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
}

.statsItem .userName {
  flex: 1;
  margin-left: 10px;
  color: white;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: start;
}

.rankIcon {
  margin-right: 8px;
  margin-top: 3px;
  height: 25px;
  width: 18px;
}

.statsItem .userScore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: white;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
}

.statsItem .userIcon {
  width: 40px;
  height: 40px;
}

.bottomNav {
  width: 90%;
  margin-top: 2rem;
}

.checkIcon {
  background-color: white;
  border-radius: 100%;
  width: 15px;
  margin: 5px;
  top: 2px;
  position: relative;
}

@media (max-width: 380px) {
  .statsList {
    max-height: 40vh;
  }
}
