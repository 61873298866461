.bottomButtons {
  position: fixed;
  width: 92%;
  left: 4%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 25px;
  background-color: #29224c;
  border-radius: 99px;
}

.btn-text {
  border: none;
  font-size: 13px;
  background: transparent;
  color: white;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 2s infinite;
}
