.walletContainer {
  background-color: rgba(33, 17, 52, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  /* z-index: 999; */
  position: relative;
}

.pfBar {
  margin: 0 auto;
  width: 90%;
}

.overlay {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.radial1 {
  display: flex;
  width: 100%;
  position: absolute;
  top: 7rem;
  width: 150px;
  height: 180px;
  background: rgba(91, 17, 12, 0.955);
  filter: blur(100px);
}
.radial2 {
  display: flex;
  width: 100%;
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(18, 73, 131, 0.673);
  filter: blur(70px);
  bottom: 8rem;
}

.walletContent {
  width: 100%;
  position: absolute;
  /* top: 1rem; */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px auto;
  border-radius: 10px;
  color: white;
  font-family: "Poppins";
  font-size: 15px;
  border-radius: 10px !important;
}

td {
  border: 1px solid #ffffff;
  padding: 15px;
  border-radius: 50px;
}

.gameTitle {
  margin: 20px 0px;
  color: #89eaff;
  font-size: 18px;
  font-weight: 500;
  font-family: "Oswald";
}
.gamePackageContainer {
  background-color: rgba(255, 255, 255, 0.035);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 8px;
}
.gamePackageContainerSelected {
  background-color: rgba(255, 255, 255, 0.035);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 8px;
  border: 1px solid goldenrod;
}
.gameCardTitle {
  font-size: 23px;
  font-family: "oswald";
  font-weight: 500;
  color: "#F7F7F7";
}
.gamePackageButton {
  padding: 10px;
  background: #d87500;
  text-transform: uppercase;
  border-radius: 35px;
  width: 120px;
  border: none;
  outline: none;
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
.wallet-congrats-box {
  position: fixed;
  top: 0;
}
.gamePackageButtonInactive {
  padding: 10px;
  background: grey;
  opacity: 0.2;
  text-transform: uppercase;
  border-radius: 35px;
  width: 120px;
  border: none;
  outline: none;
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}
.leftArrowIcon {
  cursor: pointer;
}
