/* .welcomeContainer2 {
  margin: 2rem 0 1rem 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(20px);
  border-radius: 8px;
}
.welcomeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 10px 0;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 60%;
}
.lTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}
.ppBox {
  display: flex;
}
.profileContainer {
  position: relative;
}
.ppl {
  display: flex;
  top: 0;
}

.ppLevel {
  width: 88px;
  height: 60px;
}
.profilepic {
  position: absolute;
  top: 8.7px;
  left: 20.9px;
}
.frogImg {
  width: 46px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
  object-fit: cover;
}
.welBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 5px;
}
.welText {
  font-size: 12px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
}
.nameBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pepeName {
  color: #ffffff;
  font-size: 16px;
  margin: 0 4px 2px 0;
  font-family: "Oswald", sans-serif;
}
.levelIcon {
  width: 25px;
}
.lBottom {
  display: flex;
  align-items: center;
  margin: 1rem 0 8px 0;
  max-width: 100%;
}
.levelText {
  color: rgba(255, 183, 44, 1);
  font-size: 10px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(30px);
  border: 2px solid rgba(217, 217, 217, 0.1);
  border-radius: 3rem;
  padding: 1px 10px;
  margin-right: 5px;
  text-align: center;
}
.levelscore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.9rem;
  margin-bottom: 1px;
}
.progressBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: rgba(209, 255, 210, 0.253);
  position: relative;
  height: 20px;
  border-radius: 3rem;
  position: relative;
}
.circle {
  position: absolute;
  background-color: rgba(168, 212, 170, 0.4);
  width: 17px;
  height: 17px;
  border-radius: 3rem;
  left: 2.2px;
  top: 1.7px;
  z-index: 10;
}
.greenBar {
  background: linear-gradient(269.84deg, #4cc820 2.96%, #048919 96.87%);
  position: absolute;
  height: 17px;
  width: 17px;
  left: 2.2px;
  top: 1.7px;
  border-radius: 3rem;
}
.barRank {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rank {
  font-size: 8px;
  z-index: 2;
  position: absolute;
  font-family: "Oswald", sans-serif;
  color: rgba(255, 255, 255, 0.5);
}
.rank span {
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  font-family: "Oswald", sans-serif;
}
.starIcon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  margin: 2px;
}

.arrowIcon {
  margin-left: 5px;
  background-color: rgba(216, 117, 0, 1);
  backdrop-filter: blur(30px);
  border-radius: 3rem;
  border: 1px soli rgba(246, 5, 5, 0.1);
  padding: 3px;
  color: #ffffff;
  font-size: 15px;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}
.connectBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.025);
  border: 1px solid rgba(255, 255, 255, 0.056);
  backdrop-filter: blur(5px);
  padding: 1px 14px;
  border-radius: 3rem;
}
.connIcon {
  line-height: 0;
  margin: 4px 3px 3px 0;
  width: 10px;
  height: 8px;
}
.conn {
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
}
.credBox {
  width: 8.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.PEPECoinImg {
  width: 55px;
  margin-right: 4px;
  margin-top: 10px;
}
.creds {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;
}
.pepeText {
  font-size: 10px;
  letter-spacing: 2px;
  font-family: "Oswald", sans-serif;
}
.pepeCount {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  line-height: 24px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 3px;
}
.buyButton {
  margin-top: 4px;
  background-color: rgba(216, 117, 0, 1);
  border-radius: 3rem;
  padding: 0 20px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
}

.rank {
  font-size: 8px;
  z-index: 2;
  font-family: "Oswald", sans-serif;
  color: rgba(255, 255, 255, 0.5);
}
.rank span {
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  z-index: 2;
  font-family: "Oswald", sans-serif;
}
.starIcon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  margin: 2px;
}

@media (max-width: 375px) {
  .levelscore {
    width: 6rem;
  }
  .progressBox {
    height: 17px;
  }
  .circle {
    width: 14px;
    height: 14px;
    left: 1.5px;
    top: 1.4px;
  }
  .greenBar {
    height: 14px;
    width: 14px;
    left: 1.5px;
    top: 1.4px;
  }
  .rank {
    font-size: 6px;
  }
  .rank span {
    font-size: 6px;
  }
  .starIcon {
    font-size: 7px;
    margin: 2px;
  }
  .levelText {
    font-size: 9px;
    padding: 1px 8px;
    margin-right: 3px;
  }
  .arrowIcon {
    font-size: 10px;
    margin-left: 3px;
  }
} */

.welcomeContainer2 {
  margin: 1rem 0 10px 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  width: 93vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(40px) !important;
  border-radius: 8px;
  /* -webkit-backdrop-filter: blur(40px); */
}
.welcomeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 6px 0 5px 0;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 60%;
  margin-bottom: 5px;
}
.lTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}
.ppBox {
  display: flex;
  /* width: 100%; */
}
.profileContainer {
  position: relative;
}
.ppl {
  display: flex;
  top: 0;
}

.ppLevel {
  width: 88px;
  height: 60px;
}
.profilepic {
  position: absolute;
  top: 8.7px;
  left: 20.9px;
  border-radius: 50%;
}
.frogImg {
  width: 46px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
}
.welBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 5px;
}
.welText {
  font-size: 12px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
}
.nameBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pepeName {
  color: #ffffff;
  font-size: 16px;
  margin: 0 4px 2px 0;
  font-family: "Oswald", sans-serif;
}
.levelIcon {
  width: 25px;
}
.checkIcon {
  background-color: white;
  border-radius: 100%;
  width: 15px;
  margin-right: 5px;
}
.lBottom {
  display: flex;
  align-items: center;
  margin: 6px 0 1px 0;
  max-width: 100%;
}
.levelText {
  color: rgba(255, 183, 44, 1);
  font-size: 10px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(30px);
  border: 2px solid rgba(217, 217, 217, 0.1);
  border-radius: 3rem;
  padding: 1px 10px;
  margin-right: 5px;
  text-align: center;
}
.levelscore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.9rem;
  margin-bottom: 1px;
}
.progressBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: rgba(209, 255, 210, 0.253);
  position: relative;
  height: 20px;
  border-radius: 3rem;
  position: relative;
}
.circle {
  position: absolute;
  background-color: rgba(168, 212, 170, 0.4);
  width: 17px;
  height: 17px;
  border-radius: 3rem;
  left: 2.2px;
  top: 1.7px;
  z-index: 10;
}
.greenBar {
  background: linear-gradient(269.84deg, #4cc820 2.96%, #048919 96.87%);
  position: absolute;
  /* width: 80%; */
  height: 17px;
  width: 17px;
  left: 2.2px;
  top: 1.7px;
  border-radius: 3rem;
}
.barRank {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rank {
  font-size: 8px;
  position: absolute;
  font-family: "Oswald", sans-serif;
  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.rank span {
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  z-index: 2;
  font-family: "Oswald", sans-serif;
}
.starIcon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  margin: 2px;
}

.arrowIcon {
  margin-left: 5px;
  background-color: rgba(216, 117, 0, 1);
  backdrop-filter: blur(30px);
  border-radius: 3rem;
  border: 1px soli rgba(246, 5, 5, 0.1);
  padding: 3px;
  color: #ffffff;
  font-size: 15px;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}
.connectBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid white;
  backdrop-filter: blur(30px);
  padding: 1px 14px;
  border-radius: 3rem;
}
.connIcon {
  line-height: 0;
  margin: 4px 3px 3px 0;
  width: 10px;
  height: 8px;
}
.conn {
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
}
.credBox {
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.PEPECoinImg {
  width: 45px;
  margin-right: 4px;
  margin-top: 10px;
}
.mytapcredBox {
  margin-right: 15px;
}
.creds {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;
}
.pepeText {
  font-size: 10px;
  letter-spacing: 2px;
  font-family: "Oswald", sans-serif;
  /* font-weight: 500; */
  width: 5rem;
}
.pepeCount {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  line-height: 14px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 3px;
}
.buyButton {
  margin-top: 4px;
  background-color: rgba(216, 117, 0, 1);
  border-radius: 3rem;
  padding: 0 20px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 375px) {
  .levelscore {
    width: 6rem;
  }
  .progressBox {
    height: 17px;
  }
  .circle {
    width: 14px;
    height: 14px;
    left: 1.5px;
    top: 1.4px;
  }
  .greenBar {
    height: 14px;
    width: 14px;
    left: 1.5px;
    top: 1.4px;
  }
  .rank {
    font-size: 6px;
  }
  .rank span {
    font-size: 6px;
  }
  .starIcon {
    font-size: 7px;
    margin: 2px;
  }
  .levelText {
    font-size: 9px;
    padding: 1px 8px;
    margin-right: 3px;
  }
  .arrowIcon {
    font-size: 10px;
    margin-left: 3px;
  }
}

@media (max-width: 375px) {
  .welcomeContainer {
    margin-top: 10px;
  }
  .welcomeBox {
    margin: 4px 0 1px 0;
  }
  .lTop {
    margin-top: 0px;
  }
  .lBottom {
    margin-top: 4px;
    height: 19px;
  }
  .ppLevel {
    width: 68px;
    height: 47px;
  }
  .frogImg {
    width: 36px;
    height: 35px;
  }
  .profilepic {
    top: 6.2px;
    left: 15.8px;
  }
  .welText {
    font-size: 10px;
  }
  .pepeName {
    font-size: 14px;
  }
  .levelIcon {
    width: 22px;
  }
  .levelscore {
    width: 5rem;
  }
  .progressBox {
    height: 14.25px;
  }
  .circle {
    width: 12px;
    height: 12px;
    left: 1.5px;
    top: 1px;
  }
  .greenBar {
    height: 12px;
    width: 12px;
    left: 1.5px;
    top: 1px;
  }
  .rank {
    font-size: 6px;
  }
  .rank span {
    font-size: 6px;
  }
  .starIcon {
    font-size: 7px;
    margin: 2px;
  }
  .levelText {
    font-size: 7.8px;
    padding: 0.2px 6px;
    margin-right: 3px;
    margin-bottom: 1px;
    border: 1px solid rgba(217, 217, 217, 0.1);
  }
  .arrowIcon {
    font-size: 7px;
    margin-left: 3px;
    margin-bottom: 1px;
  }
  .credBox {
    width: 7rem;
    margin-bottom: 5px;
  }
  .connectBox {
    padding: 0.2px 8px;
  }
  .connIcon {
    width: 8px;
    height: 6px;
  }
  .conn {
    font-size: 8px;
  }
  .PEPECoinImg {
    width: 43px;
    margin-top: 5px;
  }
  .pepeText {
    font-size: 8px;
  }
  .pepeCount {
    font-size: 16px;
    line-height: 15px;
  }
  .buyButton {
    font-size: 8px;
    margin-top: 3px;
  }
  .topCardDiv {
    padding: 2px 0;
    margin-top: 2px;
  }
  .mytapcredBox {
    margin-right: 0;
  }
  .card2 {
    width: 40%;
  }
  .creditCoinImg {
    width: 50px;
    height: 50px;
    margin-right: 2px;
  }
  .total-credit-text {
    font-size: 10px;
    width: 6rem;
  }
  .total-credit-number {
    font-size: 24px;
    line-height: 24px;
  }
}

@media (max-height: 610px) {
  .beacon {
    margin-top: 2.5rem;
  }
  .tapdiv {
    max-width: 200px;
    max-height: 200px;
  }
  .tabImg {
    max-width: 200px;
    max-height: 200px;
  }
  .newtap {
    height: 250px;
    width: 250px;
  }
  .beacon {
    margin-top: 0;
  }
  .rank-data img {
    height: 18px;
    width: 15px;
  }
}

@media (max-height: 570px) {
  .tapdiv {
    width: 40vw;
    height: 40vw;
  }
  .tabImg {
    width: 40vw;
    height: 40vw;
  }
  .beacon {
    margin-top: 1.5rem;
  }
}
