.body {
  background-color: rgba(33, 17, 52, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-y: scroll;
  width: 100vw;
  position: relative;
}
.overlay {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
}
.radial1 {
  display: flex;
  width: 100%;
  position: absolute;
  top: 7rem;
  width: 150px;
  height: 180px;
  background: rgba(91, 17, 12, 0.955);
  filter: blur(100px);
}
.radial2 {
  display: flex;
  width: 100%;
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(5, 65, 129, 0.953);
  filter: blur(70px);
  bottom: 10rem;
}

.statContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.pfBar {
  width: 93vw;
}

.inviteEarnBox {
  width: 90%;
  margin-top: 8px;
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  padding: 10px 0;
  border-radius: 16px;
  color: white;
}

.inviteEarnHeader {
  font-size: 18px;
  margin-bottom: 10px;
  padding: 0 1.5rem 14px 1rem;
  color: rgba(137, 234, 255, 1);
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.073);
}

.inviteOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 8px;
  overflow-x: scroll;
}
.inviteOptions::-webkit-scrollbar {
  display: none;
}

.inviteOption {
  /* width: 6rem; */
  background-color: rgba(255, 255, 255, 0.045);
  border: 1px solid rgba(255, 255, 255, 0.011);
  backdrop-filter: blur(10px);
  padding: 5px 10px 0 7px;
  border-radius: 6px;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 4px;
  position: relative;
}
.completed {
  background-color: rgba(17, 144, 8, 0.119);
}
.inviteText {
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: rgb(255, 255, 255);
  font-family: "Oswald", sans-serif;
  font-weight: 350;
}
.checkMark {
  font-size: 1rem;
  color: rgb(11, 211, 11);
  padding: 0;
  margin: 0;
}
.inviteAmount {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inviteOption img {
  width: 25px;
  margin-top: 4px;
}
.amount {
  margin: 0 0 3px 7px;
  font-size: 15px;
  letter-spacing: 1px;
  color: rgb(255, 255, 255);
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}
.inviteButtons {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.inviteButton {
  background-color: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.011);
  backdrop-filter: blur(10px);
  padding: 7px 2rem;
  border-radius: 20px;
  font-size: 13px;
  color: rgb(255, 255, 255);
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  text-align: center;
  margin: 0 5px 0 0;
  display: flex;
  align-items: center;
}
.inviteButton2 {
  background-color: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.011);
  backdrop-filter: blur(10px);
  padding: 8px;
  border-radius: 3rem;
  font-size: 13px;
  color: rgb(255, 255, 255);
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
}

.linkIcon {
  margin-right: 5px;
  color: rgba(255, 218, 0, 1);
  font-size: 1.2rem;
}

.copyIcon {
  margin: 0;
  color: rgba(216, 117, 0, 1);
  font-size: 1rem;
}

.invitedBox {
  width: 93%;
  margin-top: 20px;
  margin-bottom: 105px;
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  padding: 8px 0 20px 0;
  border-radius: 16px;
  color: white;
}

.invitedHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
  font-size: 16px;
  color: rgba(137, 234, 255, 1);
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.073);
}

.sortBox {
  display: flex;
  align-items: center;
}
.sortOption {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.item {
  font-size: 11px;
  font-family: "Oswald", sans-serif;
  font-weight: 350;
  color: white;
}
.activeSort .item {
  color: rgba(238, 129, 0, 1);
}

.checkMark2 {
  font-size: 14px;
  margin-right: 4px;
  color: rgba(255, 255, 255, 0.5);
}

.activeSort .checkMark2 {
  color: rgba(238, 129, 0, 1);
}

.invitedList {
  padding: 0 12px 0 5px;
  background-color: rgba(49, 5, 103, 0.175);
}

.invitedItem {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.041);
  background: rgba(255, 255, 255, 0.053);
  background-blend-mode: overlay;
  backdrop-filter: blur(40px);
  padding: 10px 12px;
  border-radius: 3rem;
  margin-bottom: 10px;
}

.ppl {
  display: flex;
  top: 0;
}

.ppLevel {
  width: 57px;
  height: 40px;
}
.profilePic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.profilepic {
  position: absolute;
  top: 15px;
  left: 25.5px;
  /* width: 36px;
    height: 36px; */
  position: absolute;
  border-radius: 50%;
}

.invitedItem .userInfo {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 10px;
}

.userName {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  font-size: 14px;
  font-family: "Oswald", sans-serif;
  color: white;
  border-right: 1px solid rgba(255, 255, 255, 0.188);
}

.userCredits {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.CreditsMoney {
  width: 30px;
}
.creditsAmount {
  margin-left: 5px;
  font-size: 14px;
  font-family: "Oswald", sans-serif;
  color: white;
  letter-spacing: 2px;
}

.userStatus {
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-family: "Oswald", sans-serif;
  color: white;
}
.statusDot {
  border: 2.5px solid rgba(0, 0, 0, 0.27);
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 3rem;
  background-color: rgba(33, 169, 39, 1);
}
.statusDot2 {
  border: 2.5px solid rgba(0, 0, 0, 0.27);
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 3rem;
  background-color: rgba(249, 249, 249, 0.547);
}
.userStatus.offline {
  .statusDot {
    background-color: red;
  }
}

.checkIcon {
  background-color: white;
  border-radius: 100%;
  width: 15px;
  margin: 5px;
  top: 1px;
  position: relative;
}

@media (max-width: 350px) {
  .invitedBox {
    width: 95%;
  }
  .invitedList {
    padding: 0 15px;
  }
}
