.confirm-tx-container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: rgba(144, 143, 143, 0.273);
  backdrop-filter: blur(5px);
}

.confirm-tx-content {
    background: rgba(33, 16, 46, 0.9); /* Match the color from your screenshot */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: white;
    width: 90%;
    display: block;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
  }