.taskContainer {
  background-color: rgba(33, 17, 52, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
}

.overlay{
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.radial1 {
  display: flex;
  width: 100%;
  position: absolute;
  top: 7rem;
  width: 150px;
  height: 180px;
  background: rgba(91, 17, 12, 0.955);
  filter: blur(100px);
}
.radial2 {
  display: flex;
  width: 100%;
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(18, 73, 131, 0.673);
  filter: blur(70px);
  bottom: 8rem;
}

.taskContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  padding-bottom: 100px;
  top: -7rem;
}
.pfBarr2 {
  width: 93vw;
}
 .iconSection{
  display: flex;
  margin-top: 7rem;
 }
.iconSection img {
  text-align: center;
  object-fit: contain;
  width: 100%;
  margin-bottom: 10px;
  height: 11rem;
}
.starTitle {
  text-align: center;
  font-family: "oswald";
  font-weight: 400;
  font-size: 15px;
  line-height: 25.17px;
  color: #89eaff;
  margin-top: -10px;
}
.socialAccouts3rx{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95vw;
}
.SocialAccountBox {
  border-radius: 9px;
  background: rgba(101, 76, 192, 0.212);
  color: #ffffff4b;
  width: 93vw;
  margin: 8px auto;
}