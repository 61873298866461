.body {
  background-color: rgba(33, 17, 52, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-y: scroll;
  width: 100vw;
  position: relative;
}
.overlay {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
}
.radial1 {
  display: flex;
  width: 100%;
  position: absolute;
  top: 7rem;
  width: 150px;
  height: 180px;
  background: rgba(91, 17, 12, 0.955);
  filter: blur(100px);
}
.radial2 {
  display: flex;
  width: 100%;
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(18, 73, 131, 0.673);
  filter: blur(70px);
  bottom: 8rem;
}

.congratsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  /* height: 5rem; */
  /* background: linear-gradient(95.39deg, #7F35DD 0%, #2900CD 103.77%); */
  /* text-align: center; */
  /* color: rgba(255, 255, 255, 1); */
  top: 0;
  /* margin: -11rem 0 0 0; */
  /* font-size: 20px; */
  /* border-radius: 8px; */
  position: absolute;
  left: 0;
  /* top: -3rem; */
  /* width: 93vw; */
  /* transform: translate(-50% ,-100%); */
}
.confetti {
  z-index: 10000;
}
.connectBuyContainer {
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  background: rgba(144, 143, 143, 0.195);
  backdrop-filter: blur(5px);
}

.connectBuyContent {
  background: rgba(33, 16, 46, 0.9);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  bottom: 0;
}

.BuyContent {
  /* margin-top: 2rem; */
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.closebtn {
  font-size: 20px;
}

.BuyContent p {
  font-size: 14px;
  margin: 0 0 5px 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 250;
}

.BuyContent h1 {
  margin-bottom: 0;
  text-align: center;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}

.price {
  font-size: 18px;
  margin-top: 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.balance {
  color: rgba(255, 234, 108, 1);
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.buyLevelIcon {
  width: 8rem;
}

.connectBuyButton {
  background-color: rgba(255, 255, 255, 0.071);
  backdrop-filter: blur(5px);
  color: rgba(108, 255, 84, 1);
  border: none;
  padding: 10px 3rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 255, 8, 0.155);
  margin: 12px 0 1.5rem 0;
  border-radius: 3rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}

.statContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100vw; */
}

.pfBar {
  width: 100%;
  z-index: 0;
}
.yellowBackground {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1.3px solid goldenrod;
  border-bottom: 1.3px solid goldenrod !important;
  border-radius: 3px;
}
.rankBox {
  width: 90vw;
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  border-radius: 16px;
}

.rankHeader {
  display: flex;
  justify-content: left;
  padding: 10px 5%;
  color: rgba(137, 234, 255, 1);
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.138);
  border-radius: 16px 16px 0 0;
}
.headElement {
  width: 40%;
}
.headElement2 {
  width: 20%;
}
.headElement3 {
  width: 25%;
}

.rankItem {
  width: 95%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: white;
  margin-top: 5px;
  border-radius: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.077);
}

.rankIcon {
  width: 40px;
  margin-right: 7px;
}

.rankTask {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: rgb(255, 255, 255);
  text-align: start;
  width: 8rem;
}
.energyParent {
  width: 100px;
  display: flex;
  justify-content: center;
}
.energycnt {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: rgb(255, 255, 255);
  text-align: center;
  width: 20rem;
}

.rankName {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: "Oswald", sans-serif;
  font-weight: 350;
  width: 30%;
  color: rgb(255, 255, 255);
}

.levelBox {
  width: 90vw;
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  margin-bottom: 5rem;
}
.personalCongratsBox {
  position: fixed;
  top: 0;
}
.levelHeader {
  display: flex;
  justify-content: flex-start;
  padding: 10px 1.5rem;
  color: rgba(137, 234, 255, 1);
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.138);
  border-radius: 16px 16px 0 0;
}

.lheadElement {
  width: 37%;
}
.lheadElement2 {
  width: 40%;
}
.levelItem {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: white;
  border-radius: 5px;
  margin-top: 1px;
  border-radius: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.105);
}

.levelIcon {
  width: 46px;
  margin-left: 10px;
}
.pertapIcon {
  width: 36px;
}

.buyButton {
  padding: 6px 2.5rem;
  background-color: rgba(255, 255, 255, 0.001);
  border: 1px solid rgba(255, 255, 255, 0.115);
  backdrop-filter: blur(10px);
  color: white;
  border-radius: 3rem;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}

.bottomNav {
  width: 90%;
  margin-top: 2rem;
}
