.body{
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 12px;
    margin: 0;
    background-color: rgba(26, 6, 46, 0.9);
    z-index: 11000;
    position: fixed;
}
.hidden{
    display: none;
}

.content{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin-left: 10px;
}

.silverPepe{
    width: 45px;
}

.msgBox{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.greet{
    color: rgba(255, 195, 40, 1);
    font-size: 12px;
    font-family: "Oswald", sans-serif;
    font-weight: 500; 
}

.greetMsg{
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-family: "Poppin", sans-serif;
    font-weight: 500; 
}