.video-background {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.loading-background-gif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.316);
  z-index: -2;
  background: url("../../media/loading_start.gif");
  background-position: center;
  background-size: cover;
}

.enter-background-gif {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.316);
  background: url("../../media/enter.gif");
  background-position: center;
  background-size: cover;
}

@supports (-webkit-overflow-scrolling: touch) {
  .loading-background-gif {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.316);
    z-index: -2;
    background: url("../../media/loading_start.gif");
    background-position: center;
    background-size: cover;
  }

  .enter-background-gif {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.316);
    z-index: -2;
    background: url("../../media/enter.gif");
    background-position: center;
    background-size: cover;
  }
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.201);
  /* Adjust the color and opacity here */
  z-index: -1;
}

s .content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  padding: 20px;
}

.tapToEarnMainDiv {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
  position: absolute;
  top: 5%;
  justify-content: space-between;
  z-index: 2;
}

.tapToEarnMainDiv .hand-icon {
  font-size: 40px;
  margin: 0;
}

.tapToEarnMainDiv .tap-text {
  font-size: 17px;
  font-weight: 800;
  color: white;
  margin-top: 28px;
}


.loading-enter-btn {
  border: 1px solid rgba(255, 255, 255, 0.839);
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  cursor: pointer;
  z-index: 999;
}

.loading-enter-btn p {
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.839);
  letter-spacing: 3px;
}

.image-container {
  display: inline-block;
  transition: transform 0.2s ease, background-color 0.2s ease;
  position: fixed;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  height: 50vw;
  width: 50vw;
  max-width: 220px;
  max-height: 220px;
  border-radius: 50%;
}

.image-container.tapped {
  transform: scale(0.9);
  background-color: rgba(0, 0, 255, 0);
  /* Semi-transparent blue background */
}

.tappable-image {
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.beacon-loading {
  border-radius: 99;
  animation: shine 2s ease-in-out infinite;
}

.tapdiv-loading {
  z-index: 999;
  /* background: linear-gradient(#577cff, #2731eb, #3f1cc8, #0e338d); */
  height: 50vw;
  width: 50vw;
  max-width: 240px;
  max-height: 240px;
  border-radius: 50%;
  position: fixed;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.5s ease;
}

.tapdiv-loading:active {
  /* background: linear-gradient(#3f1cc8, #0e338d, #577cff, #2731eb); */
}

.allIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  margin-inline: auto;
  width: 100%;
}

.loadingIconDiv {
  background: linear-gradient(#35353f, #161819, #0e0d0f);
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
}

.loadingIcons {
  width: 32px;
}

.loaderDiv {
  position: fixed;
  top: 75%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
}

.loaderDiv img {
  width: 60px;
  height: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.loaderDiv p {
  color: white;
  font-size: 15px;
  margin: 0;
  text-align: center;
}



.loadingMainDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 9px;
  padding-inline: 4px;
  background-color: aliceblue;
  height: 25px;
  width: 200px;
  border-radius: 99px;
}

.loadingDiv {
  background-color: rgb(233, 204, 10);
  height: 20px;
  border-radius: 99px;
  z-index: 99;
}