.privateSaleContainer {
  background-color: rgba(33, 17, 52, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
}

.overlay {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.radial1 {
  display: flex;
  width: 100%;
  position: absolute;
  top: 7rem;
  width: 150px;
  height: 180px;
  background: rgba(91, 17, 12, 0.955);
  filter: blur(100px);
}
.radial2 {
  display: flex;
  width: 100%;
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(18, 73, 131, 0.673);
  filter: blur(70px);
  bottom: 8rem;
}

.privateSaleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  position: absolute;
  padding-bottom: 100px;
  top: -7rem;
}
.pfBarr2 {
  width: 93vw;
}
.iconSection {
  display: flex;
  margin-top: 7rem;
  width: 100%;
  height: 40%;
  align-items: center;
}
.iconSection img {
  text-align: center;
  object-fit: contain;
  width: 100%;
  margin-bottom: 10px;
  height: 80%;
}
.starTitle {
  text-align: center;
  font-family: "oswald";
  font-weight: 400;
  font-size: 15px;
  line-height: 25.17px;
  color: #89eaff;
  margin-top: -10px;
}
.contentModal3rx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
}
.contentModalBox {
  border-radius: 9px;
  background: rgba(101, 76, 192, 0.212);
  color: #ffffff4b;
  width: 93vw;
  margin: 8px auto;
}
.privateSalePepe {
  font-family: Oswald;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.7878620624542236px;
  text-align: center;
  color: #ffffff;
  width: 125px;
  height: 50px;
  position: relative;
  text-transform: uppercase;
}

.privateSaleHeadings {
  width: 162px;
  height: 18px;
  color: #ffda00;
  font-family: Oswald;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  margin: 1em;
}

.privateSaleHeadingSelected {
  width: 162px;
  height: 25px;
  color: rgba(216, 117, 0, 1);
  font-family: Oswald;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin: 2%;
  text-align: center;
}

.privateSaleHeadingNotSelected {
  width: 162px;
  height: 25px;
  color: rgba(255, 255, 255, 1);
  font-family: Oswald;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin: 2%;
  text-align: center;
}


.line {
  width: 100%;
  height: 5px;
  background-color: #e0e0e0; /* Base line color */
  position: relative;
  margin-top: 10px;
}

.highlight {
  position: absolute;
  height: 100%;
  background-color: rgba(216, 117, 0, 1); /* Highlight color */
  width: 50%; /* Initial width, changed via JS */
  transition: width 0.3s ease; /* Smooth transition */
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pie-chart {
  animation: fadeIn 1s ease-in forwards, rotate 2s linear infinite;
  transform-origin: center;
  opacity: 0;
}

.rotating {
  animation: rotate 2s linear infinite;
}

.loaded {
  animation: rotate 1.5s linear;
  opacity: 1;
}