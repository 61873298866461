.body{
    background-color: rgba(33, 17, 52, 1);
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow-y: scroll;
    width: 100vw;
    position: relative;
}
.body::-webkit-scrollbar{
    display: none;
}
.overlay{
    /* z-index: 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
}
.radial1{
    display: flex;
    width: 100%;
    position: absolute;
    top: 7rem;
    width: 150px;
    height: 180px;
    background: rgba(91, 17, 12, 0.955);
    filter: blur(100px);
}
.radial2{
    display: flex;
    width: 100%;
    position: absolute;
    width: 200px;
    height: 200px;
    background: rgba(5, 65, 129, 0.953);
    filter: blur(70px);
    bottom: 10rem;
}

.statContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;  
    z-index: 1100;
    position: absolute;
    top: 3rem;
}
.headBox{
    width: 85%;
}

.faqTop{
    width: 59%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 3rem 0;
    font-size: 30px;
    color: rgb(255, 255, 255);
    font-family: "Poppin", sans-serif;
    font-weight: 500;
    z-index: 1000;
}
.backArrow{
    font-size: 30px;
}

.faqContainer{
    /* width: 100vw; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 90%;
    padding: 10px 0;
}
.faqContainer::-webkit-scrollbar{
    display: none;
}
.faqBox {
    flex: 0 0 auto;
    width: 300px;
    margin: 20px 10px;
    background-color: rgba(255, 255, 255, 0.035);
    border: 1px solid rgba(255, 255, 255, 0.041);
    backdrop-filter: blur(5px);
    padding: 20px 20px 3.5rem 20px;
    border-radius: 16px;
    color: white;
  }
  
  .faqImage {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .faqTitle {
    font-size: 22px;
    color: rgb(255, 255, 255);
    font-family: "Poppin", sans-serif;
    font-weight: 550;
    margin: 1.5rem 0 10px 0;
  }
  
  .faqContent {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.827);
    font-family: "Poppin", sans-serif;
    font-weight: 250;
    margin-top: 1.5rem;
    line-height: 28px;
  }
