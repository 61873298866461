.main-game {
  position: relative;
  height: 100vh;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.calenderModalpfx {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}
.walletModalpfx {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}
.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.8;
}
.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100vw;
  /* overflow-y: scroll; */
  justify-content: space-between;
  top: -6px;
}
.playFixedDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.walletDiv {
  display: none;
  justify-content: space-between;
  width: 100%;
  max-width: 92%;
  margin-top: 15px;
  margin-inline: auto;
  background: #100f2f;
  border: 1px solid r;
  color: white;
  border-radius: 99px;
  border: 1px solid #8272dd;
  position: relative;
}

.walletDiv p {
  margin-left: 20px;
  font-size: 16px;
}

.walletDiv p span {
  font-size: 16px;
  font-weight: 700;
}

.walletDiv div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.walletDiv div p {
  font-size: 15px;
  font-weight: 700;
}

.background-gif {
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.316);
  background: url("../../media/game-bar.gif");
  background-position: center;
  background-size: cover;
  opacity: 0.75;
}

.connect-wallet-container {
  /* z-index: 1300; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: rgba(144, 143, 143, 0.273);
  backdrop-filter: blur(5px);
}
.closeIcon_ax {
  font-size: 20px;
}
.connect-wallet-content {
  background: rgba(33, 16, 46, 0.9); /* Match the color from your screenshot */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  /* width: 90%; */
  width: 93vw;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* bottom: 0; */
}
.wallet-content {
  /* margin-top: 2rem; */
  /* margin: 0 auto; */
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}
.wallet-content p {
  margin-top: 10px;
  text-align: center;
}
.wallet-content h1 {
  margin-bottom: 0;
  text-align: center;
  line-height: 35px;
}

.connIcon2 {
  width: 90px;
}

.connect-wallet-button {
  background-color: rgba(255, 255, 255, 0.071); /* Button color */
  backdrop-filter: blur(5px);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 12px 0 1.5rem 0;
  border-radius: 3rem;
}

.connect-wallet-button:hover {
  background-color: #333;
}

.welcome-header {
  width: 92%;
  margin: 0 auto;
  color: white;
}

.welcome-header p {
  margin: 5px 0;
}

.anime {
  z-index: 1000;
  pointer-events: none;
  user-select: none;
  color: white;
  font-size: 30px !important;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  position: absolute;
  animation: visibility 2s ease-out forwards;
  /* animation:transform 3s; */
  opacity: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* @keyframes transform {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  0% {
    opacity: 1;
    transform: translate(-50%, -20%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -10%);
  }
} */

@keyframes visibility {
  from {
    opacity: 100%;
    transform: translateY(0);
  }

  to {
    opacity: 0%;
    transform: translateY(-300px);
  }
}

.newtap {
  z-index: 998;
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 333px;
  height: 330px;
  background-color: transparent;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.tapdiv {
  z-index: 999;
  height: 64vw;
  width: 64vw;
  max-width: 270px;
  max-height: 270px;
  border-radius: 50%;
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.5s ease;
}

.plus-one {
  position: absolute;
  top: 50%; /* Example positioning */
  left: 50%; /* Example positioning */
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  animation: flyUp 1s ease-out forwards;
  pointer-events: none; /* Prevent pointer events on the flying +1 */
}

@keyframes flyUp {
  0% {
    opacity: 1;
    transform: translateY(0) translateZ(0); /* Reset transform */
  }
  25% {
    opacity: 1;
    transform: translateY(-30px) translateZ(0); /* Move up higher */
  }
  100% {
    opacity: 0;
    transform: translateY(-100px) translateZ(0); /* Move further up and fade out */
  }
}

.tabImg {
  position: absolute;
  top: 53%;
  left: 50%;
  z-index: 999;
  height: 64vw;
  width: 64vw;
  max-width: 270px;
  max-height: 270px;
  border-radius: 50%;
  background: url("../../media/frogTap.png") no-repeat center center;
  background-size: cover;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.3s ease; /* Transition for tilt effect */
}

.wave {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 50px; /* Example width of the wave */
  height: 50px; /* Example height of the wave */
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* White color with transparency */
  border-radius: 50%; /* Circular shape */
  /* animation: ripple 0.5s linear; */
  animation: tapWave 0.5s ease-out forwards;
  pointer-events: none; /* Ensure waves don't interfere with clicks */
  z-index: 1000; /* Ensure waves are above the coin */
}

@keyframes tapWave {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.beacon {
  /* margin-top: 10px; */
  border-radius: 99;
  animation: shine 2s ease-in-out infinite;
}

@keyframes shine {
  0%,
  20% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.49);
  }

  100% {
    box-shadow: 0px 0px 0px 50px rgba(63, 62, 62, 0);
  }
}
/* .welcomeContainer {
  margin: 1rem 0 2px 0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.001);
  backdrop-filter: blur(20px);
  border-radius: 8px;
} */
.welcomeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 6px 0 3px 0;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 60%;
}
.lTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}
.ppBox {
  display: flex;
  /* width: 100%; */
}
.profileContainer {
  position: relative;
}
.ppll {
  display: flex;
  top: 0;
}

.ppLevelll {
  width: 88px;
  height: 60px;
}
.profilepicc {
  position: absolute;
  top: 8.7px;
  left: 20.9px;
  border-radius: 50%;
}
.frogImggg {
  width: 46px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
}
.welBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 5px;
}
.welText {
  font-size: 12px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
}
.nameBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pepeName {
  color: #ffffff;
  font-size: 16px;
  margin: 0 4px 2px 0;
  font-family: "Oswald", sans-serif;
}
.levelIcon {
  width: 25px;
}
.lBottom {
  display: flex;
  align-items: center;
  margin: 6px 0 1px 0;
  max-width: 100%;
}
.levelText {
  color: rgba(255, 183, 44, 1);
  font-size: 10px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(30px);
  border: 2px solid rgba(217, 217, 217, 0.1);
  border-radius: 3rem;
  padding: 1px 10px;
  margin-right: 5px;
  text-align: center;
}
.levelscore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.9rem;
  margin-bottom: 1px;
}
.progressBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: rgba(209, 255, 210, 0.253);
  position: relative;
  height: 20px;
  border-radius: 3rem;
  position: relative;
}
.circle {
  position: absolute;
  background-color: rgba(168, 212, 170, 0.4);
  width: 17px;
  height: 17px;
  border-radius: 3rem;
  left: 2.2px;
  top: 1.7px;
  z-index: 10;
}
.greenBar {
  background: linear-gradient(269.84deg, #4cc820 2.96%, #048919 96.87%);
  position: absolute;
  /* width: 80%; */
  height: 17px;
  width: 17px;
  left: 2.2px;
  top: 1.7px;
  border-radius: 3rem;
}
.barRank {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rank {
  font-size: 8px;
  position: absolute;
  font-family: "Oswald", sans-serif;
  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.rank span {
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  z-index: 2;
  font-family: "Oswald", sans-serif;
}
.starIcon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  margin: 2px;
}

.arrowIcon {
  margin-left: 5px;
  background-color: rgba(216, 117, 0, 1);
  backdrop-filter: blur(30px);
  border-radius: 3rem;
  border: 1px soli rgba(246, 5, 5, 0.1);
  padding: 3px;
  color: #ffffff;
  font-size: 15px;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}
.connectBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid white;
  backdrop-filter: blur(30px);
  padding: 1px 14px;
  border-radius: 3rem;
}
.connIcon {
  line-height: 0;
  margin: 4px 3px 3px 0;
  width: 10px;
  height: 8px;
}
.conn {
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
}
.credBox {
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.PEPECoinImg {
  width: 45px;
  margin-right: 4px;
  margin-top: 10px;
}
.mytapcredBox {
  margin-right: 15px;
}
.creds {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;
}
.pepeTextt {
  font-size: 10px;
  letter-spacing: 2px;
  font-family: "Oswald", sans-serif;
  /* font-weight: 500; */
  width: 5rem;
}
.pepeCount {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  line-height: 19px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 3px;
}
.buyButton {
  margin-top: 4px;
  background-color: rgba(216, 117, 0, 1);
  border-radius: 3rem;
  padding: 0 20px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  cursor: pointer;
  text-decoration: none;
}

.topCardDiv {
  color: white;
  border-radius: 8px;
  width: 100%;
  /* margin-top: 5px; */
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.001);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.card2 {
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topCardCoinDiv {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.creditCoinImg {
  height: 55px;
  width: 55px;
  margin-left: auto;
  margin-right: 6px;
}

.total-credit-text {
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0;
  margin: 0;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  width: 7rem;
  text-align: start;
}

.total-credit-number {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 30px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 2px;
}

.pre-sale-price {
  font-size: 14px;
  margin: 0;
  width: 100%;
  font-weight: 800;
  margin-top: 14px;
}

.pre-sale-text {
  color: #ffec42;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  width: 100%;
  font-weight: 700;
  text-align: center;
}

.paly-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  font-weight: 500;
  margin-bottom: 6rem;
}
.rank-data {
  width: 95%;
  display: flex;
  justify-content: flex-end;
}
.energy-boost-box {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Qmark {
  margin-left: 6px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #6865f15a;
  border-radius: 3rem; */
}
.right-play-data {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.boost-button {
  color: white;
  padding: 3px 1px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  margin-bottom: 2px;
}

@media (max-width: 375px) {
  .levelscore {
    width: 6rem;
  }
  .progressBox {
    height: 17px;
  }
  .circle {
    width: 14px;
    height: 14px;
    left: 1.5px;
    top: 1.4px;
  }
  .greenBar {
    height: 14px;
    width: 14px;
    left: 1.5px;
    top: 1.4px;
  }
  .rank {
    font-size: 6px;
  }
  .rank span {
    font-size: 6px;
  }
  .starIcon {
    font-size: 7px;
    margin: 2px;
  }
  .levelText {
    font-size: 9px;
    padding: 1px 8px;
    margin-right: 3px;
  }
  .arrowIcon {
    font-size: 10px;
    margin-left: 3px;
  }
}

@media (max-width: 375px) {
  .welcomeContainer {
    margin-top: 10px;
  }
  .welcomeBox {
    margin: 4px 0 1px 0;
  }
  .lTop {
    margin-top: 0px;
  }
  .lBottom {
    margin-top: 4px;
    height: 19px;
  }
  .ppLevelll {
    width: 68px;
    height: 47px;
  }
  .frogImggg {
    width: 36px;
    height: 35px;
  }
  .profilepicc {
    top: 6.2px;
    left: 15.8px;
  }
  .welText {
    font-size: 10px;
  }
  .pepeName {
    font-size: 14px;
  }
  .levelIcon {
    width: 22px;
  }
  .levelscore {
    width: 5rem;
  }
  .progressBox {
    height: 14.25px;
  }
  .circle {
    width: 12px;
    height: 12px;
    left: 1.5px;
    top: 1px;
  }
  .greenBar {
    height: 12px;
    width: 12px;
    left: 1.5px;
    top: 1px;
  }
  .rank {
    font-size: 6px;
  }
  .rank span {
    font-size: 6px;
  }
  .starIcon {
    font-size: 7px;
    margin: 2px;
  }
  .levelText {
    font-size: 7.8px;
    padding: 0.2px 6px;
    margin-right: 3px;
    margin-bottom: 1px;
    border: 1px solid rgba(217, 217, 217, 0.1);
  }
  .arrowIcon {
    font-size: 7px;
    margin-left: 3px;
    margin-bottom: 1px;
  }
  .credBox {
    width: 7rem;
    margin-bottom: 5px;
  }
  .connectBox {
    padding: 0.2px 8px;
  }
  .connIcon {
    width: 8px;
    height: 6px;
  }
  .conn {
    font-size: 8px;
  }
  .PEPECoinImg {
    width: 43px;
    margin-top: 5px;
  }
  .pepeTextt {
    font-size: 8px;
  }
  .pepeCount {
    font-size: 16px;
    line-height: 15px;
  }
  .buyButton {
    font-size: 8px;
    margin-top: 3px;
  }
  .topCardDiv {
    padding: 2px 0;
    margin-top: 2px;
  }
  .mytapcredBox {
    margin-right: 0;
  }
  .card2 {
    width: 40%;
  }
  .creditCoinImg {
    width: 50px;
    height: 50px;
    margin-right: 2px;
  }
  .total-credit-text {
    font-size: 10px;
    width: 6rem;
  }
  .total-credit-number {
    font-size: 24px;
    line-height: 24px;
  }
}

@media (max-height: 610px) {
  .beacon {
    margin-top: 2.5rem;
  }
  .tapdiv {
    max-width: 200px;
    max-height: 200px;
  }
  .tabImg {
    max-width: 200px;
    max-height: 200px;
  }
  .newtap {
    height: 250px;
    width: 250px;
  }
  .beacon {
    margin-top: 0;
  }
  .rank-data img {
    height: 18px;
    width: 15px;
  }
}

@media (max-height: 570px) {
  .tapdiv {
    width: 40vw;
    height: 40vw;
  }
  .tabImg {
    width: 40vw;
    height: 40vw;
  }
  .beacon {
    margin-top: 1.5rem;
  }
}
